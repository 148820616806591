import { createStore } from 'vuex'

export default createStore({
  state: {
    firstTicker: null,
    secondTicker: null,
    firstValue: null,
    secondValue: null,
  },
  getters: {
  },
  mutations: {

  },
  actions: {
  },
  modules: {
  }
})
