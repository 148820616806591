<template>
  <footer :class="{'white' : !isMain}">
    <div class="container">
<!--      <div class="footer__top">-->
<!--        <div class="footer__top_left">-->
<!--          <p class="footer__top_left-title">Download our mobile app for your Android or iOS device</p>-->
<!--          <DownloadApp class="download" :changeMobileSize="true"/>-->
<!--        </div>-->
<!--      </div>-->
      
      
      <div class="footer__mid">
        <div class="footer__buy footer__buy-first">
          <div v-for="item, i in footer.top" class="footer__list">
            <h4 @click="openList(item)">{{item.title}}</h4>
            <ul v-if="item.isOpen">
              <li v-for="link in item.links">
                <a href="#" target="_blank">{{ link }}</a>
              </li>
            </ul>
          </div>
        </div>
  
        <div class="footer__buy footer__buy-second">
          <div class="footer__list">
            <h4 @click="openList('buy')">{{footer.buy.title}}</h4>
            <div class="footer__list_body">
              <ul v-for="linksArr, i in footer.buy.links" v-if="footer.buy.isOpen">
                <li v-for="link in linksArr">
                  <a href="#" target="_blank">Buy {{ link }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
  
        <div class="footer__buy">
          <div class="footer__list">
            <h4 @click="openList('exchange')">{{footer.exchange.title}}</h4>
            <div class="footer__list_body">
              <ul v-for="linksArr, i in footer.exchange.links" v-if="footer.exchange.isOpen">
                <li v-for="link in linksArr">
                  <a href="#" target="_blank">{{ link }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      
      
      
      
      
      
      
      <div class="footer__bottom">
          <ul class="social__links">
            <li v-for="i in 10" :key="i">
              <a href="#" target="_blank">
                <img :src="require(`@/assets/img/footer/social/${11-i}.svg`)" alt="">
              </a>
            </li>
          </ul>
          <div class="footer__copyright">© Changelly 2015—2023</div>
      </div>
    </div>
  </footer>
</template>

<script>


import DownloadApp from "@/components/DownloadApp";
export default {
  components: {DownloadApp},
  data() {
    return {
      footer: {
        top: [
          {
            title: 'Company',
            isOpen: false,
            links: ['About', 'Supported currencies','Press about us','Our partners','Reviews','Changelly PRO','Crypto App',]
          },
          {
            title: 'Support',
            isOpen: false,
            links: ['FAQ','Helpdesk','Blog','Crash course']
          },
          {
            title: 'For partners',
            isOpen: false,
            links: ['Affiliate Program','API for developers','Listings']
          },
          {
            title: 'Legal',
            isOpen: false,
            links: ['Terms of Use','Privacy Policy','For competent authorities','For partners', 'AML/KYC']
          }
        ],
        buy: {
          title: 'Buy',
          isOpen: false,
          links: [['Bitcoin','Ethereum','Monero','Litecoin'],['Cardano','Dogecoin','Ripple','BNB'],['Tron','Nexo','Vechain','USDC'],['USDT (Tether)','Stellar','Bitcoin Cash','DigiByte'],['Ravencoin','EOS','SHIB','Polkadot']]
        },
        exchange: {
          title: 'Exchange',
          isOpen: false,
          links: [['Exchange Bitcoin','Exchange ETH','Exchange altcoins'],['ETH to BTC','BTC to ETH','LTC to ETH'],['USDT to BTC','BTC to XRP','BTC to USDT'],['LTC to BTC','XRP to BTC','BCH to BTC'],['ETH to USDT','DOGE to BTC','BTC to DOGE']]
        }
      },
      windowWidth: 0
    }
  },
  computed: {
    tablet() {
      return this.windowWidth <= 1024
    },
    isMain() {
      return this.$route.path === '/' || this.$route.path === '/defi'
    }
  },
  methods: {
    showFooterItems() {
      this.footer.exchange.isOpen = !this.tablet
      this.footer.buy.isOpen = !this.tablet
      this.footer.top.forEach(el => el.isOpen = !this.tablet)
    },
    openList(item) {
      if (this.tablet) {
        this.showFooterItems()
        if (typeof item === 'string') {
          this.footer[item].isOpen = !this.footer[item].isOpen
        } else {
          let indexOfTopItem = this.footer.top.indexOf(this.footer.top.find(obj => obj.title === item.title))
          this.footer.top[indexOfTopItem].isOpen = true
        }
      }
    }
  },
  mounted() {
    this.windowWidth = window.innerWidth
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
    if (!this.tablet) {
      this.showFooterItems()
    }
  },
  watch: {
    tablet() {
      this.showFooterItems()
    }
  }
}
</script>

<style scoped lang="scss">
footer {
  background: #20262A;
}

.footer__top {
  padding: 33px 0;
  border-bottom: 1px solid rgba(128, 163, 182, 0.22);
}

.footer__top_left {
  display: flex;
  gap: 36px;
}

.footer__top_left-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 143%;
  color: #9B9B9B;
  width: 212px;
  flex-shrink: 0;
}

.footer__mid {
  padding: 33px 0;
  border-bottom: 1px solid rgba(128, 163, 182, 0.22);
}

.footer__list {
  h4 {
    font-weight: 600;
    font-size: 14px;
    line-height: 115%;
    color: #FFFFFF;
    margin-bottom: 16px;
  }
  li {
    margin-bottom: 16px;
  }
  a {
    height: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #9B9B9B;
    &:hover {
      transition: .3s;
      color: white;
    }
  }
}
.footer__buy-first {
  display: flex;
  gap: 32px;
  .footer__list {
    width: 166px;
  }
}
.footer__buy-second {
  margin: 32px 0;
}

.footer__buy {
}
.footer__list_body {
  display: flex;
  gap: 32px;
  ul {
    width: 166px;
  }
}
.footer__bottom {
  display: flex;
  padding: 32px 0;
  justify-content: space-between;
  align-items: center;
}

.social__links {
  display: flex;
  gap: 16px;
  img {
    width: 24px;
    height: 24px;
    transition: .3s;
    &:hover {
      opacity: .5;
    }
  }
}

.footer__copyright {
  font-weight: 400;
  font-size: 14px;
  line-height: 115%;
  color: #9B9B9B;
}


@media (max-width: $tablet) {
  .container {
    max-width: 100%;
  }
  .footer__top_left {
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    align-items: center;
  }
  .footer__top_left-title {
    width: 100%;
    text-align: center;
  }
  
  .footer__buy-first {
    gap: 0;
    flex-direction: column;
    .footer__list {
      width: 100%;
    }
  }
  .footer__list_body {
    flex-direction: column;
    gap: 16px;
    ul {
      width: 100%;
    }
  }
  .footer__buy-second {
    margin: 0;
  }
  .footer__list_body {
    gap: 0;
  }
}



@media (max-width: $tabletMini) {
  .footer__bottom {
    flex-direction: column;
    gap: 16px;
  }
}


@media (max-width: $mobile) {
  .social__links {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
  .download {
    flex-direction: column;
    .item {
      background: red;
    }
  }
}

.download {
  @media (max-width: $tablet) {
    justify-content: center;
  }
}


.white {
  background: white;
  .footer__top_left-title, .footer__copyright {
    color: #181C1F;
  }
  .footer__buy {
    h4 {
      color: #181C1F;
    }
    a {
      color: #181c1f;
      &:hover {
        color: #181c1f;
        opacity: .5;
      }
    }
  }
}
</style>
