<template>
  <div class="items" >
    <div class="item" v-for="item, i in items" :key="item" :class="{'changeMobileSize' : changeMobileSize}">
      <img :src="item" :alt="`download-icon-${i}`">
    </div>
  </div>
</template>

<script>
export default {
  name: "DownloadApp",
  props: ['changeMobileSize'],
  data() {
    return {
      items: [
        require('@/assets/img/donwload/google-play.svg'),
        require('@/assets/img/donwload/appstore.svg'),
        require('@/assets/img/donwload/android.svg')
      ],
      
    }
  }
}
</script>

<style scoped lang="scss">
.items {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  width: 100%;
}
.item {
  width: 128px;
  height: 40px;
  background: #2C3033;
  border-radius: 5px;
  transition: .3s;
  cursor: pointer;
  &:hover {
    background: #181C1F;
  }
  &.changeMobileSize {
    display: flex;
    justify-content: center;
    @media (max-width: $mobile) {
      width: 100%;
    }
  }
}
</style>
