<template>
  <header :class="{'white' : !isMain}" >
    <div class="container header__block">
      <router-link to="/" class="logo">
        <img :src="isMain ? require('@/assets/img/logo.svg') : require('@/assets/img/logo-dark.svg')" alt="">
      </router-link>
      <div class="header__bottom">
        <nav>
          <ul>
            <li><a href="https://t.me/kriss19951" target="_blank">Telegram support</a></li>
          </ul>
        </nav>
      </div>
    </div>
  </header>
  
</template>

<script>
export default {
  data() {
    return {
      burger: false
    }
  },
  computed: {
    isMain() {
      return this.$route.path === '/' || this.$route.path === '/defi'
    }
  }
}
</script>

<style scoped lang="scss">
header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: #181C1F;
  z-index: 10;
}
.container {
  padding: 18px 20px;
  border-bottom: 1px #2C3033 solid;
  @media (max-width: $tablet) {
    width: 100%;
    height: 56px;
    padding: 0;
    display: flex;
    align-items: center;
    margin: 0;
    max-width: unset;
    .logo {
      padding-left: 24px;
      @media (max-width: $tabletMini) {
        img {
          width: 97px;
        }
      }
    }
  }

}
.header__block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header__bottom {
  display: flex;
  align-items: center;
  height: 48px;
  ul {
    display: flex;
    padding: 0 16px;
    li {
      margin-left: 24px;
      a {
        font-size: 14px;
        line-height: 20px;
        position: relative;
        color: $white;
        &:before {
          width: 100%;
          content: '';
          background: #06a45c;
          height: 2px;
          position: absolute;
          top: 31px;
        }
      }
    }
  }
}

.white {
  background: #FAFAFA;
  .container {
    border-color: #DFE4E8;
  }
  .header__bottom {
    .active {
      color: #181c1f !important;
    }
  }
}
</style>
