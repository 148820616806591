<template>
  <VHeader />
  <main>
    <router-view/>
  </main>
  <VFooter />
</template>

<script>

import VHeader from "@/components/v-header";
import VFooter from "@/components/v-footer";
import axios from "axios";
export default {
  name: 'App',
  components: {VHeader, VFooter},
  data: () => ({
    //
  }),
}
</script>


<style lang="scss" scoped>
main {
  padding-top: 72px;
  padding-bottom: 72px;
}
</style>
